import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import cardData from './cardData';
import image1 from '../assets/images/border.webp';
import image2 from '../assets/images/target.webp';
import image3 from '../assets/images/Ford.webp';

// Map your image imports to the ids or titles in cardData
const images = {
  image1: image1,
  image2: image2,
  image3: image3,
};

function ContentCards() {
  return (
    <Container>
      <Row className="g-4">
        {cardData.map((card, idx) => (
          <Col md={4} key={idx}>
            <Card>
              <Card.Img variant="top" src={images[card.id]} /> {/* Use the mapped image */}
              <Card.Body>
                <Card.Title>{card.title}</Card.Title>
                <Card.Text>{card.text}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ContentCards;
