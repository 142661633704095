import React from 'react';
import { Container, Row, Col, Button, Navbar, Nav } from 'react-bootstrap';
import ContentCards from './contentCards'; // Make sure the path matches where your file is located
import '.././styles.css'

const LandingPage = () => {
  return (
    <div>
      {/* Navigation Bar */}
      <Navbar bg="primary" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#">Real American Matters</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">US</Nav.Link>
              <Nav.Link href="#link">Politics</Nav.Link>
              <Nav.Link href="#link">World</Nav.Link>
              <Nav.Link href="#link">Trending</Nav.Link>
              {/* Add more links as needed */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Content */}
      <Container className="my-4 hero-banner">
        <Row className="align-items-center">
          <Col lg={8}>
            <h1>Breaking News</h1>
            <p>Your Place For All News That Matters</p>
            <Button variant="primary">Learn more</Button>
          </Col>
        </Row>
      </Container>

      {/* Modular Content Cards */}
      <ContentCards />

      {/* Donation and Ads Section */}
      <Container className="my-4">
        <Row>
          <Col md={8}>
            <Button variant="success" size="lg">Donate</Button>
          </Col>
          <Col md={4}>
            <div className="bg-light p-3 text-center">
              Advertisement Space
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingPage;
