const cardData = [
    {
      id: 'image1',
      title: 'Border',
      text: 'GOP SENATORS DEMAND "ADEQUATE TIME" TO REVIEW BORDER SECURITY BILL',
      url: 'https://www.foxnews.com/politics/gop-senators-demand-adequate-time-review-border-security-bill'
    },
    {
      id: 'image2',
      title: 'Target',
      text: 'HIGH-VALUE TARGET BREAKING NEWS US airstrike in Iraq takes out terrorist involved in attack that killed 3 US soldiers',
      url: 'https://www.foxnews.com/world/drone-strike-baghdad-kills-high-ranking-commander-involved-attack-that-killed-3-us-soldiers'
    },
    {
      id: 'image3',
      title: 'Ford',
      text: 'Ford considers axing car feature that could save company millions per year.',
      url:'https://www.foxbusiness.com/markets/ford-considers-axing-car-feature-could-save-company-millions-per-year'
    },
  ];
  
  export default cardData;
  